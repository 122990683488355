export default {
	cinema: {
		address: 'пр. Красноярский рабочий, д 173 Б',
		place: null,
		phoneNumbers: ['8 (391) 2-777-888'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinoepicentr',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://apps.apple.com/ru/app/кинотеатр-эпицентр/id1492446873',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.epicenter',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBUUQOg9wA',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
